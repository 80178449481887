@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in {
    opacity: 1;
    animation: fadeIn ease-in-out 1s;
    transform: translate(0px, 0px);
    transition: transform 1s linear;
}

.title-fade-in {
    opacity: 1;
    animation: fadeIn ease-in-out 1s;
    transform: translate(0px, 0px);
    transition: transform 1s linear;
}
.title-fade-out {
    opacity: 1;
    animation: fadeOut ease-in-out 1s;
    transform: translate(20px, 30px);
    transition: transform 1s linear;
}

.fade-out {
    opacity: 1;
    animation: fadeOut ease-in-out 1s;
    transform: translate(-20px, -30px);
    transition: transform 1s linear;
}

:is(.one, .two, .three, .four, .five, .six) {
    /* animation: bubble 1s ease; */
    opacity: 0;
    animation-fill-mode: forwards;
}

.one {
    animation-delay: 0.2s;
}

.two {
    animation-delay: 0.4s;
}

.three {
    animation-delay: 0.6s;
}

.four {
    animation-delay: 0.8s;
}

.five {
    animation-delay: 1s;
}

.six {
    animation-delay: 1.2s;
}
